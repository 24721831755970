import { Avatar, Box, Card, CardContent, Grid, Modal, Typography } from "@mui/material";

const UndefinedError = ({ error }: { error: any }) => {
  return <Box>
	<Modal
		open={true}
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description"
	>
		<Card sx={{ 
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: 600,
		}}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<Avatar
							src="/avatar.png"
							sx={{
								width: 128,
								height: 128,
							}}
						>
						</Avatar>
					</Grid>
					<Grid item xs={12} md={8}>
						<Typography variant="h3" color="text.secondary">
							Unbehandelter Fehler
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Da hat wohl jemand mal wieder nicht richtig programmiert und nicht an alles gedacht.
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	</Modal>
  </Box>
};

export default UndefinedError;