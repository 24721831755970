import axios from "axios";



const AxiosProxy = () => axios.create({
	baseURL: window?.b313?.server || 'https://headless.eppingen.org',
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
	}

});

function getCookie(cname: string) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
	  let c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return "";
  }

export default AxiosProxy;


