import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, Router, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import UserContext from './context/UserContext.tsx';
import ResetPassword from './cmps/Auth/ResetPassword.tsx';


const appRoutes = createBrowserRouter([
	{
		path: '/reset-password/:token',
		element: <ResetPassword/>,
	},
	{
		path: '/*',
		element: <App/>,
	},
]);

const THEME = createTheme({
	palette: {
		primary: {
			main: '#333',
		},
		org: {
			main: '#FFA500',
			contrastText: '#fff',
		},
		orgLight: {
			main: '#FFA500aa',
			contrastText: '#fff',
		},
	},
	typography: {
		fontFamily: `"Rubik", "Helvetica", "Arial", sans-serif`,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,

		button: {
			// remove uppercase from buttons
			textTransform: 'none',
		},
	},
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
	<ThemeProvider theme={THEME}>
		<UserContext>
		<RouterProvider router={appRoutes} />
		</UserContext>
	</ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
