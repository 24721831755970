import { Box, Container, Stack, Typography } from '@mui/material';
import { FiLoader } from 'react-icons/fi';

interface LoadingBoxProps {
	text?: string;
	iconSize?: number;
	icon?: React.ReactNode;
	spin?: boolean;
}

const LoadingBox: React.FC<LoadingBoxProps> = ({ text = 'Loading...', iconSize = 64, icon = null, spin = true }) => {
	return (
		<Container maxWidth="sm" sx={{ my: 10 }}>
			<Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
				<Box className={spin ? 'spin' : ''}>
					{icon || <FiLoader size={iconSize} />}
				</Box>
				<Box>
					<Typography variant={'h4'} gutterBottom>
						{text}
					</Typography>
					<Typography variant={'body1'}>Please wait while we load your data</Typography>
				</Box>
			</Stack>
		</Container>
	);
};

export default LoadingBox;
