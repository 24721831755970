import logo from './logo.svg';
import './App.css';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import AxiosProxy from './cmps/AxiosProxy.tsx';
import { FiFigma, FiInfo, FiLoader, FiX } from 'react-icons/fi';
import { AppBar, Box, Button, Card, CardContent, CardHeader, CardMedia, Chip, Container, Divider, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import LoadingBox from './cmps/LoadingBox.tsx';
import { BiHourglass, BiSolidBadge, BiSolidBadgeCheck } from 'react-icons/bi';
import { TbChristmasTree } from 'react-icons/tb';
import { GiPresent, GiUpgrade } from 'react-icons/gi';
import moment from 'moment';
import { UserContextProvider } from './context/UserContext.tsx';
import Login from './cmps/Auth/Login.tsx';
import { BsEye, BsInfo, BsInfoCircleFill } from 'react-icons/bs';
import UndefinedError from './cmps/Error/UndefinedError.tsx';
// import { useSearchParams } from 'react-router-dom';







function App() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [initialLoading, setInitialLoading] = useState(true);
	const [overviewData, setOverviewData] = useState(null);
	const [unhandledError, setUnhandledError] = useState(false);

	const { user, setUser } = useContext(UserContextProvider)
	
	const account = overviewData?.account;
	const contracts = overviewData?.contracts;
	const opportunities = overviewData?.opportunities;
	const marktplatzEintrag = overviewData?.marktplatzEintrag;

	const theme = useTheme();
	
	// get query param: ?id=123
	const id = searchParams.get('id');
	const [tempSelectedKunde, setTempSelectedKunde] = useState(id);




	const handleChangeSelectedKunde = () => {
		setSearchParams({id: tempSelectedKunde});
	}

	const handleLogout = () => AxiosProxy().post('/logout')
	.then(res => {
		window.location.reload();
	});


	useEffect(() => {
		// fetch Initial Data
		AxiosProxy()
			.get('/org2b/init')
			.then(res => {
				setOverviewData(res.data.data);
				setUser(res.data.data.user);
				console.log(res);
			})
			.catch(err => {
				if(err.response.status === 401) {
					setUser(null);
					setOverviewData(null);
				} else {
					setUnhandledError(true);
				}
			})
			.finally(() => {
				setInitialLoading(false);
			});

	}, [user?.id]);

	if(unhandledError) return (<UndefinedError />);
	
	if(initialLoading) return (<LoadingBox text="Bitte warten Sie während wir Ihre Übersicht laden"/>);

	if(!user) {
		return (
			<Container maxWidth='sm' sx={{
				my: 5,
			}}>
				<Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={3}>
					<img src='https://jobzone.eppingen.org/org-weiss.png' width={64} height={64} />
					<Typography variant={'h4'} ml={2} color={'#fff'}>Kundenportal</Typography>
				</Box>
				<Login/>
			</Container>
		)
	}



	if(!overviewData || !account) return (<LoadingBox spin={false} icon={<FiX size={64} />} text="Dieser Kunde ist nicht auffindbar"/>)

  return (
    <div className="CustomerHubApp">
		<AppBar position='static'>
			<Toolbar sx={{
			}}>
				<Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} flexGrow={1}>
					<img src='https://jobzone.eppingen.org/org-weiss.png' width={48} height={48} />
					<Typography variant={'h6'} ml={2} color={'#fff'}>Kundenportal</Typography>
				</Box>
				<Box>
					<Button color='org' variant='outlined' onClick={handleLogout}>
						Logout
					</Button>
				</Box>
			</Toolbar>
		</AppBar>
			
		{/* <Box
			bgcolor={'#000'}
			sx={{
				width: '100%',
				height: '500px',
				backgroundImage: "url('/weihnachtsgruesse-banner.png')",
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'end',
			}}
		>
			<svg width="100%" height="50" viewBox="0 0 500 80" preserveAspectRatio="none" color='green'>
				<path d="M0,0 Q250,80 500,0 L500,80 L0,80 Z" fill="#313131" fill- 
					rule="evenodd" />
			</svg>
			
		</Box> */}
				
		<Container sx={{mt:3}}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					{/* Karte zum Marktplatzeintrag */}
					<Card>
						<CardHeader title="EPPINGEN.org Status"/>

						{!marktplatzEintrag ? (<CardContent>
							Sie haben noch keinen Marktplatzeintrag!<br/>
							Buchen Sie jetzt Ihren Eintrag auf EPPINGEN.org und entlocken Sie das ganze Potential der digitalen Region.
							<Button variant='text' size='small' color='primary' startIcon={<GiUpgrade size={16} />} href="https://marketing.eppingen.org/der-marktplatz/uebersicht.html" target="_blank">
								Jetzt upgraden
							</Button>
						</CardContent>) : <Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<CardContent sx={{
									display: 'flex',
									flexDirection: 'column',
									height: '100%',
									alignItems: 'center',
									gap: 1
								}}>
									<Typography variant='caption' fontWeight={'bold'}>
										Verfügbare Freibeiträge:
										<Typography variant='inherit' component='div' color={'text.secondary'} fontWeight={300}>
											(Seit: {marktplatzEintrag?.highestLevel?.currentPeriodStartDate})
										</Typography>
									</Typography>
									<Typography variant='h1' color={marktplatzEintrag?.highestLevel?.color} sx={{
										display: 'flex',
										alignItems: 'center',
									}}>
										{marktplatzEintrag?.anzahlFreibetraege - marktplatzEintrag?.anzahlGenutzerFreibeitraege}
										<Typography variant='subtitle1' component='span' color={'text.secondary'}>/{marktplatzEintrag?.anzahlFreibetraege}</Typography>
									</Typography>
									<Typography variant='subtitle1' color={marktplatzEintrag?.highestLevel?.color} sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 1,
									}} fontWeight={'bold'}>
										<BiSolidBadgeCheck size={20} color={marktplatzEintrag?.highestLevel?.textColor} />
										{marktplatzEintrag?.highestLevel?.name}
									</Typography>
									<Button variant='text' size='small' color='primary' startIcon={<GiUpgrade size={16} />} href="https://marketing.eppingen.org/der-marktplatz/uebersicht.html" target="_blank">
										Jetzt upgraden
									</Button>

									<Stack direction={'row'} spacing={1}>
										<BsInfoCircleFill size={16} color={'#ff9800'} />
										<Typography variant='caption' color={'text.secondary'}>
											Ihre Freibeiträge erneuern sich wieder am {marktplatzEintrag?.highestLevel?.nextReplenishmentDate}
										</Typography>
									</Stack>

								</CardContent>
							</Grid>
							<Grid item xs={12} md={6}>
								<CardContent sx={{
									display: 'flex',
									flexDirection: 'column',
									height: '100%',
									alignItems: 'center'
								}}>
									<Box display={'flex'} gap={1} flexDirection={'column'}>
									<Typography variant='caption'>Beitrag auf EPPINGEN.org schalten:</Typography>
									<Button variant='outlined' size='medium' color='error' startIcon={<TbChristmasTree/>} href="https://marketing.eppingen.org/werben-auf-org/weihnachts-und-neujahrsgruesse.html" target="_blank">
										Weihnachtsgrüße senden
									</Button>

									<Button variant='outlined' size='medium' color='primary' href="https://jobzone.planit-online.de" target="_blank">
										#JOBZONE
									</Button>
									<Button variant='outlined' size='medium' color='primary' href="https://marketing.eppingen.org/werben-auf-org/beitraganzeige.html" target="_blank">
										Aktionsbanner buchen
									</Button>
									<Button variant='outlined' size='medium' color='primary' href="https://marketing.eppingen.org/werben-auf-org/stellenanzeigen.html" target="_blank">
										Stellenanzeige buchen
									</Button>
									</Box>
								</CardContent>
							</Grid>
						</Grid>}
						<Divider sx={{mt:3}}/>
						<CardHeader subheader="Meine Buchungen" />
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Produkt</TableCell>
										<TableCell>Buchung am</TableCell>
										<TableCell>Betrag EUR</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{opportunities.map((opportunity) => {
										return <TableRow sx={{
											bgcolor: opportunity?.isCurrent !== true ? 'rgba(0,0,0,0.13)' : 'transparent',
											color: opportunity?.isCurrent !== true ? 'text.secondary' : 'inherit',
										}}>
											<TableCell>
												{opportunity.productpositionsdisplay}
											</TableCell>
											<TableCell>{opportunity.pit_gewonnen_am?.length == 10 ? 
															moment(opportunity.pit_gewonnen_am, "DD.MM.YYYY", "de").format('DD.MM.YYYY')
															: moment(opportunity.pit_gewonnen_am).format('DD.MM.YYYY')
														}				
											</TableCell>
											<TableCell align='right'>
												 
												{opportunity?.opptotalamount?.toFixed(2).replace('.',',')}

											</TableCell>
										</TableRow>
									})}
								</TableBody>
							</Table>
					
					</Card>

				</Grid>
				<Grid item xs={12} md={6}>
					{/* Werbung */}
					<Card sx={{mb:3, backgroundImage: "url('https://pulsar.eppingen.org/images/ads/weihnachtsgruesse.png')", textAlign:'right', backgroundSize: 'cover', backgroundPosition: 'center'}}>
						<CardContent>
							<Typography variant="h4" sx={{color: '#fff',textShadow: '2px 2px 0px #884c01'}} gutterBottom>Weihnachtsgrüße senden</Typography>
							<Typography variant="body1" sx={{color: '#fff',textShadow: '2px 2px 0px #884c01'}} gutterBottom>
							Frohe Vorweihnachtszeit!<br/>Verbreiten Sie vorfreudige Stimmung und hinterlassen Sie jetzt Ihren festlichen Gruß für Kunden und Mitarbeiter auf EPPINGEN.org
							</Typography>
							<Button variant='contained' color='primary' startIcon={<TbChristmasTree/>} href="https://marketing.eppingen.org/werben-auf-org/weihnachts-und-neujahrsgruesse.html" target="_blank">
								Weihnachtsgrüße senden
							</Button>
							<Divider sx={{my:2, height:2,bgcolor:'primary.main'}}/>
							<Stack direction={'row'} spacing={1}>
								<Chip label="6 Wochen Laufzeit" color='orgLight' icon={<BiHourglass/>} />
								<Chip label="Top-Thema" color='orgLight' icon={<BsEye />} />
								<Chip label="Überraschungsaktion" color='orgLight' icon={<GiPresent/>} />
							</Stack>
						</CardContent>
					</Card>
					
					{/* Karte für Stammdaten */}
					<Card>
						<CardHeader title="Meine Stammdaten"/>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell>Kundennummer:</TableCell>
										<TableCell width={'50%'}>{account.planit_kundennummer}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Unternehmensname:</TableCell>
										<TableCell width={'50%'}>{account.compname}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Adresse:</TableCell>
										<TableCell>
											{account.street1}<br/>
											{account.zip1} {account.town1}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
					</Card>


					{/* Karte für Verträge */}
					<Card sx={{my:3}}>
						<CardHeader title="Meine Verträge"/>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Produkt</TableCell>
									<TableCell>Seit</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{contracts.map((contract) => {
									return <TableRow>
										<TableCell>
											<Typography variant='inherit' color={contract?.terminationdate ? 'error' : 'primary'} fontWeight={contract?.terminationdate ? 'bold' : 'normal'}>
												{contract.product}
											</Typography>
										</TableCell>
										<TableCell>
											{moment(contract.deliverydate).format('MMMM YYYY')}
										</TableCell>

										<TableCell>
											{contract?.terminationdate ? (<Tooltip title={`Vertrag läuft aus am ${moment(contract.terminationdate).format('DD.MM.YYYY')}`}>
											<Box component={'span'}><FiInfo color={theme.palette.error.main} /></Box>
											</Tooltip>) : null}
										</TableCell>
									</TableRow>
								})}
							</TableBody>
						</Table>
					</Card>

					{/* Karte für alle Felder */}
					<Card hidden sx={{my:3}}>
						<CardContent>
							<Table>
								<TableBody>
										
									{/* {Object.keys(overviewData).map((key) => {
										return Object.keys(overviewData[key]).map((subkey) => {
											return <TableRow>
												<TableCell>{key}.{subkey}</TableCell>
												<TableCell>{JSON.stringify(overviewData[key][subkey])}</TableCell>
											</TableRow>
										})
									})} */}
								</TableBody>
							</Table>
						</CardContent>
					</Card>

				</Grid>

			</Grid>
		</Container>
    </div>
  );
}

export default App;
