import React, { useState } from "react";

type UserContextType = {
	user: any;
	setUser: React.Dispatch<React.SetStateAction<any>> | null;
};

export const UserContextProvider = React.createContext<UserContextType>({
	user: null,
	setUser: null,
});

const UserContextProps = {
	children: React.Nodes || undefined
};

const UserContext: React.FC = (
	props: UserContextProps
	) => {
	const [user, setUser] = useState<any>(null);

	return (
		<UserContextProvider.Provider value={{ user, setUser }}>
			{props.children}
		</UserContextProvider.Provider>
	);
};

export default UserContext;
