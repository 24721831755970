import { Link as MuiLink, Card, CardHeader, CardContent, Box, TextField, Button, Snackbar, Alert, Avatar, Typography, Divider, List, ListItem, ListSubheader, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AxiosProxy from "../AxiosProxy.tsx";
import { AiOutlineLoading } from "react-icons/ai";
import { FiLoader } from "react-icons/fi";
import { FaCalendar, FaUser } from "react-icons/fa";
import { BsCalendarPlus } from "react-icons/bs";
import { UserContextProvider } from "../../context/UserContext.tsx";

const CLink = Link;

export default function Login(props: { onRegisterClick: () => void }) {
	const { onRegisterClick } = props;

	const { user, setUser } = React.useContext(UserContextProvider);
	const navigate = useNavigate();
	const [loginData, setLoginData] = React.useState({
		email: "",
		password: "",
	});
	const [loginErrors, setLoginErrors] = React.useState({});
	const [blockHandleButtonClick, setBlockHandleButtonClick] =
		React.useState(false);
	const [isLoggingOut, setIsLoggingOut] = React.useState(false);
	const [blockResetPasswordRequest, setBlockResetPasswordRequest] =
		React.useState(false);

	const [successMessage, setSuccessMessage] = React.useState(null);

	const handleOnRegisterClick = () => {
		if (onRegisterClick) onRegisterClick();
		else navigate("/register");
	};

	function handleLoginSubmit() {
		if (blockHandleButtonClick) return;

		setBlockHandleButtonClick(true);
		AxiosProxy()
			.post("/login", loginData)
			.then((response) => {
				console.log("/login success", response);
				setLoginData({
					email: "",
					password: "",
				});
				setLoginErrors({});

				if(response.data.redirectTo) {
					window.location.href = response.data.redirectTo;
				} else {
					AxiosProxy()
						.get("/user/initData")
						.then((response) => {
							console.log(response);
							setUser(response.data.data);
						})
						.catch((error) => {
							console.log(error);
						})
						.finally(() => {
							setBlockHandleButtonClick(false);
						});
				}
			})
			.catch((error) => {
				console.log("/login error", error);

				if (error.response.status === 422)
					setLoginErrors(error.response.data.errors);
				else if (error.response.status === 401) {
					setLoginErrors({
						snackbar_error:
							"Diese Kombination kann nicht gefunden werden.",
					});
					setLoginData({ ...loginData, password: "" });
				} else if (error.response.status === 403) {
					setLoginErrors({
						snackbar_error: "Dein Account wurde noch nicht aktiviert.",
					});

					navigate("/aktivieren");
				}

				setBlockHandleButtonClick(false);
			});
	}

	function handleSnackbarClose() {
		setLoginErrors((prev) => {
			return { ...prev, snackbar_error: null };
		});
	}

	function logout() {
		if (isLoggingOut) return;

		setIsLoggingOut(true);
		AxiosProxy()
			.post("/logout")
			.then((response) => {
				console.log("/logout success", response);
				setUser(null);
				navigate("/");
			})
			.catch((error) => {
				console.log("/logout error", error);
			})
			.finally(() => {
				setIsLoggingOut(false);
			});
	}

	function handleOnPasswordResetRequest() {
		if (blockResetPasswordRequest) return;

		setBlockResetPasswordRequest(true);
		setLoginErrors({});

		AxiosProxy()
			.post("/requestResetPassword", { email: loginData.email })
			.then((response) => {
				console.log("/requestResetPassword success", response);
				setSuccessMessage(response.data.message);
			})
			.catch((error) => {
				if (error.response.status === 422)
					setLoginErrors(error.response.data.error);

				console.log("/requestResetPassword error", error);
			})
			.finally(() => {
				setBlockResetPasswordRequest(false);
			});
	}

	React.useEffect(() => {}, []);

	return (
		<Card>
			{successMessage}
			<Snackbar
				open={loginErrors?.snackbar_error || successMessage ? true : false}
				autoHideDuration={10000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				{loginErrors?.snackbar_error ? <Alert
					onClose={handleSnackbarClose}
					severity="error"
					sx={{ width: "100%" }}
				>
					{loginErrors?.snackbar_error}
				</Alert> : successMessage ? <Alert
					onClose={() => setSuccessMessage(null)}
					severity="success"
					sx={{ width: "100%" }}
				>
					{successMessage}
				</Alert> : null}
			</Snackbar>
			{!user ? (
				<form
					onSubmit={(event) => {
						event.preventDefault();
						handleLoginSubmit();
					}}
					action=""
					method="post"
				>
					<CardHeader
						subheader={
							"Melden Sie sich mit Ihrer Kundennummer oder Ihrer E-Mail Adresse an."
						}
					/>
					<CardContent>
						<Box mb={2}>
							<TextField
								label="Kundenummer oder E-Mail Adresse"
								error={loginErrors?.email ? true : null}
								helperText={loginErrors?.email?.toString()}
								fullWidth
								value={loginData.email}
								onChange={(event) =>
									setLoginData({ ...loginData, email: event.target.value })
								}
							/>
						</Box>
						<TextField
							label="Passwort"
							error={loginErrors?.password ? true : null}
							helperText={loginErrors?.password?.toString()}
							fullWidth
							type="password"
							value={loginData.password}
							onChange={(event) =>
								setLoginData({ ...loginData, password: event.target.value })
							}
						/>
					</CardContent>
					<Box p={1}>
						<Button
							type="submit"
							sx={{ mb: 1 }}
							onClick={handleLoginSubmit}
							disabled={blockHandleButtonClick}
							startIcon={
								blockHandleButtonClick ? <FiLoader className="spin" /> : null
							}
							size="large"
							fullWidth
							variant="contained"
							color="primary"
						>
							Jetzt anmelden
						</Button>
						<Button
							onClick={() => {
								handleOnPasswordResetRequest();
							}}
							fullWidth
							variant="text"
							disabled={blockResetPasswordRequest}
							size="small"
							startIcon={
								blockResetPasswordRequest ? (
									<FiLoader className="spin" />
								) : null
							}
						>
							Passwort vergessen?
						</Button>
					</Box>
				</form>
			) : null}
		</Card>
	);
}
