import { Alert, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Container, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import AxiosProxy from "../AxiosProxy.tsx"
import LoadingBox from "../LoadingBox.tsx"
import UndefinedError from "../Error/UndefinedError.tsx"
import { TbLoader3 } from "react-icons/tb"


const ResetPassword = () => {
	const { token } = useParams<{ token: string }>()
	const [password, setPassword] = useState<string>('')
	const [password2, setPassword2] = useState<string>('')

	const [tokenState, setTokenState] = useState<string>('LOADING')

	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ isLoading, setIsLoading ] = useState<boolean>(true)
	const [ formErrors, setFormErrors ] = useState<any>({})

	const [ successfulReset, setSuccessfulReset ] = useState<boolean>(false)

	const navigate = useNavigate()

	const handlePasswordReset = () => {
		setIsLoading(true)

		AxiosProxy()
			.post('/resetPassword/' + token, {
				password: password,
				password_confirmation: password2,
				email: searchParams.get('email')
			})
			.then(response => {
				console.log(response)
				setSuccessfulReset(true)
			})
			.catch(error => {
				setFormErrors(error.response.data.error)
				console.log(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}


	useEffect(() => {
		AxiosProxy()
			.get('/resetPassword/' + token + '/' + searchParams.get('email'))
			.then(response => {
				console.log(response)
				setTokenState('SUCCESS')

				if(response.data.success == true) {
					setTokenState('SUCCESS')
				} else {
					setTokenState('ERROR')
				}
			})
			.catch(error => {
				console.log(error)
				setTokenState('ERROR')
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])


	
	return (
		<Box my={3}>
			<Container maxWidth={'sm'}>
				{tokenState === 'LOADING' ? <LoadingBox /> 
					: <Card>
					<CardHeader title={'Passwort zurücksetzen'} />
					<CardContent>
						{tokenState === 'ERROR' ? <Alert severity={'error'}>Der Link ist ungültig oder abgelaufen.</Alert>
						: successfulReset ? <Alert severity={'success'}>Das Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit dem neuen Passwort anmelden.</Alert>
						 : <Box>
						{formErrors?.email ? <Alert severity={'error'} sx={{mb:2}}>{formErrors?.email?.join(', ')}</Alert> : null}
							
						<TextField
							disabled={isLoading}
							label={'Passwort'}
							type={'password'}
							value={password}
							onChange={e => setPassword(e.target.value)}
							fullWidth
							sx={{ mb: 1 }}
							helperText={formErrors?.password?.join(', ')}
							error={formErrors?.password ? true : false}
						/>
						<TextField
							disabled={isLoading}
							label={'Passwort wiederholen'}
							type={'password'}
							value={password2}
							onChange={e => setPassword2(e.target.value)}
							fullWidth
							sx={{ mb: 1 }}
							helperText={formErrors?.password_confirmation?.join(', ')}
							error={formErrors?.password_confirmation ? true : false}
						/>
						<Button
							onClick={handlePasswordReset}
							variant={'contained'}
							fullWidth
							disabled={isLoading}
							color={'primary'}
							size="large"
							startIcon={isLoading ? <TbLoader3 className="spin"/> : null}
						>
							Passwort zurücksetzen
						</Button>
						</Box>}
						
					</CardContent>
					<CardActions>
						<Button
							variant={'text'}
							fullWidth
							disabled={isLoading}
							color={'primary'}
							onClick={() => {
								navigate('/')
							}}
						>
							Zurück
						</Button>
					</CardActions>
				</Card>}
			</Container>
		</Box>
	)
}

export default ResetPassword